<template>
    <BT-Blade-Items
        addBladeName="courier-billable-item"
        bladeName="courier-billable-items"
        :bladesData="bladesData"
        hideActions
        navigation="courier-billable-items"
        :useServerPagination="true"
        :canDelete="false"
        :canCreate="false"
        :headers="[
            { text: 'Courier', value: 'seller.companyName', title: 1 },
            { text: 'Type', value: 'billableType' },
            { text: 'Date', value: 'dateOfItem', textFilter: 'toLongDateAndTime', subtitle: 1 },
            { text: 'Distance (Km)', value: 'distance', textFilter: 'toDisplayNumber', subtitle: 2 }]"
        title="Couriers' Billed Items" />
</template>

<script>
export default {
    name: 'Courier-Billable-Items',
    props: {
        bladesData: null
    }
}
</script>